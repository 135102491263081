<template>
    <section style="background: #FFFFFF;">
        <!--组织展示-->
        <el-col :span="3">
             <div class="orgbtndivtree">
                <el-divider>部门</el-divider>
                <el-cascader :props="props" v-model="cascadervalue" ref="department" 
                clearable size="mini" :show-all-levels="false" 
                expand-trigger="hover" popper-class="depc"
                @change="cascaderchange"></el-cascader>
            </div>
            <div class="orgtree">
             <!--中间列表-->
                <el-table
                    :data="departusers"
                    highlight-current-row
                    :row-style="{height:'40px'}"
                    :cell-style="{padding:'0px'}"
                    empty-text="请选择部门"
                    :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                    @current-change="selectDepartUserRow"
                    >
                    <el-table-column type="index" width="70px"></el-table-column>
                    <el-table-column label="姓名" prop="name" align="left"></el-table-column>
                    <!-- <el-table-column label="部门" prop="DepartmentName" align="center"></el-table-column> -->
                </el-table>
             <!-- 分页器 -->
            <el-pagination v-if="Pagination" align='center' small
                       :pager-count="5" @current-change="handleCurrentChangeDepart" :current-page="DepartcurrentPage" :page-size="DepartpageSize" layout="prev, pager, next" :page-count="Departtotal" :total="Departtotaldata">
            </el-pagination>
         </div>
        </el-col>
        <!--工作数据展示-->
        <el-col :span="21">
            <div>
                <el-form :inline="true"
                        style="width: 100%;overflow-x: auto;white-space: nowrap;margin-left: 10px;margin-top: 10px;padding-bottom:0px;"
                        size="mini">
                        <el-form-item label="选择月份筛选">
                            <el-date-picker v-model="lookdate"
                                            type="month"
                                            @change="handleDateChange"
                                            :clearable="false"
                                            placeholder="选择查看月份"></el-date-picker>
                        </el-form-item>
                    <el-form-item>
                        <el-button @click="handleExport" >导出</el-button>
                    </el-form-item>
                </el-form>
                <el-divider>部门工作任务系统未使用报表</el-divider>
            </div>
            <div v-show="isdepartment">
                <el-table
                    id="mytable"
                    :data="users" 
                    row-key = "Idaaaaaaaaaaa"
                    :indent="30"
                    :row-class-name="tableRowClassName"
                    :fit="true"
                    :row-style="{height:'40px'}"
                    :cell-style="{padding:'0px'}"
                    :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                    style="width: 100%;height:100%;">

                        <el-table-column type = "index" fixed="left"></el-table-column>
                        <!-- <el-table-column label="工作项目" prop="WorkItem" min-width="170px" show-overflow-tooltip></el-table-column> -->
                    <!-- <el-table-column v-for="col in cols" :prop="col.prop" :label="col.label" min-width="95px" ></el-table-column> -->
                        <el-table-column label="公司" prop="company" fixed="left" min-width="150px" ></el-table-column>
                        <el-table-column label="部门" prop="department" fixed="left" min-width="150px" ></el-table-column>
                        <el-table-column fixed="left" label="姓名" prop="name" ></el-table-column>
                        <el-table-column label="职务" fixed="left" prop="zhiwujibie" min-width="140px"  >
                            <!-- <template slot-scope="scope">
                                <div v-if="scope.row.levelValue==12" >员级</div>
                                <div v-else-if="scope.row.levelValue==10" >科级</div>
                                <div v-else-if="scope.row.levelValue==9" >副部级</div>
                                <div v-else-if="scope.row.levelValue==8" >正部级</div>
                                <div v-else-if="scope.row.levelValue==6" >副总级</div>
                                <div v-else-if="scope.row.levelValue==5" >总经理/总监</div>
                                <div v-else-if="scope.row.levelValue==3" >决策层</div>
                            </template> -->
                        </el-table-column>
                        <el-table-column v-for="(col,index) in cols" :prop="col.prop" :label="col.label" min-width="105px" align="center" >
                            <template slot-scope="scope">
                                <div v-if="scope.row['day'+(index+1)]<=0" style="color:red" >{{scope.row['day'+(index+1)]}}</div>
                                <div v-else >{{scope.row['day'+(index+1)]}}</div>
                            </template>
                        </el-table-column>

                        <el-table-column label="总创建任务数" prop="TaskTotal" min-width="120px" align="center">
                            <template slot-scope="scope">
                                <div style="font-weight:bold;" >{{scope.row.TaskTotal}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="总维护进度数" prop="ProgressTotal" min-width="120px" align="center">
                            <template slot-scope="scope">
                                <div style="font-weight:bold;" >{{scope.row.ProgressTotal}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="是否排除统计" prop="Exclude" min-width="80px" align="center" >
                            <template slot-scope="scope">
                                <div>{{scope.row.Exclude?'是':''}}</div>
                            </template>
                        </el-table-column>

                        <!-- <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-tooltip  placement="top" content="点击查看年度任务详情及考核方式">
                                    <el-button type="text" size="medium" @click="checkInfo(scope.row)">查看任务</el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column> -->
                    </el-table>
                    <!-- 分页器 -->
                    <!-- <el-pagination align='center' @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper" :page-count="total" :total="totaldata">
                    </el-pagination> -->
                    
                    <el-dialog
                        :visible.sync="checkVisible" 
                        v-model="checkVisible" 
                        :close-on-press-escape="false"
                        :close-on-click-modal="false"
                        style="width: 145%; left: -400px; top:-40px"
                        center
                        >
                        <template slot="title">
                            <span style="color:#FFFFFF;font-size: 16px;">查看任务</span>   
                        </template>
                        <div class="managercheckdiv">
                            <el-col :span="12">
                                <el-form :model="checkForm" label-width="85px" ref="checkForm" style="white-space:nowrap;">
                                <el-form-item label="任务类型:" prop="CommonTaskClassifyText">
                                    <el-input v-model="CommonTaskClassifyText" disabled></el-input>
                                </el-form-item>
                                <div v-show="showregularproject">
                                        <el-row>
                                            <el-col :span="17">
                                                <el-form-item label="例会来源:" prop="MeetingName">
                                                    <el-input v-model="checkForm.MeetingName" disabled></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="7">
                                                <el-form-item label="审核人:" prop="ApprovalName">
                                                    <el-input v-model="checkForm.ApprovalName" disabled></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </div>
                                    <div v-show="showprofessionmeetong">
                                        <el-row>
                                            <el-col :span="17">
                                                <el-form-item label="会议名称:" prop="MeetingName">
                                                    <el-input v-model="checkForm.MeetingName" disabled type="textarea" :autosize="{minRows:1,maxRows:2}"></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="7">
                                                <el-form-item label="指令人:" prop="InstructUserName">
                                                    <el-input v-model="checkForm.InstructUserName" disabled></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </div>
                                <el-form-item label="工作项目:" prop="WorkItem">
                                    <el-input v-model="checkForm.WorkItem" disabled></el-input>
                                </el-form-item>
                                <el-form-item label="工作描述:" prop="WorkDescription">
                                    <el-input v-model="checkForm.WorkDescription" type="textarea" disabled :autosize="{minRows:1,maxRows:4}"></el-input>
                                </el-form-item>
                                    <el-row>
                                        <el-col :span="8" v-show="createPerson">
                                        <el-form-item label="创建人:" prop="CreatedBy">
                                            <el-input v-model="checkForm.CreatedBy" disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                        <el-col :span="8" v-show="showAssign">
                                        <el-form-item label="指派人:" prop="AssignName">
                                            <el-input v-model="checkForm.AssignName" disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="责任人:" prop="PersonOfDuty">
                                            <el-input v-model="checkForm.PersonOfDuty" disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row style="height: 40px; margin-bottom: 20px;">
                                    <el-col :span="12">
                                        <el-form-item label="计划完成时间:" prop="PlanComplateTime" label-width="35%">
                                            <el-date-picker v-model ="checkForm.PlanComplateTime" type = "date" value-format="yyyy-MM-dd" disabled style="width: 90%"></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="创建时间:" prop="CreateTime" label-width="40%">
                                            <el-date-picker v-model ="checkForm.CreateTime" type = "date" value-format="yyyy-MM-dd" disabled style="width: 90%"></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <div v-show="showyearplan">
                                    <el-form-item label="考核依据:" prop="ExamBasis">
                                        <el-input v-model="checkForm.ExamBasis" type="textarea" disabled :autosize="{minRows:1,maxRows:4}"></el-input>
                                    </el-form-item>
                                    <el-form-item label="核算方式:" prop="ScoreCalcMode">
                                        <el-input v-model="checkForm.ScoreCalcMode" type="textarea" disabled :autosize="{minRows:1,maxRows:4}"></el-input>
                                    </el-form-item>
                                    <el-form-item label="权重:" prop="Rate">
                                        <el-input v-model="checkForm.Rate" type="number" disabled></el-input>
                                    </el-form-item>
                                </div>
                                <div v-show="showorganize">
                                        <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="发起部门:" prop="OutDepartment">
                                                <el-input v-model="checkForm.OutDepartment" disabled></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="发起人员:" prop="OutPeople">
                                                <el-input v-model="checkForm.OutPeople" disabled></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="跨入部门:" prop="AcceptDepartment">
                                                <el-input v-model="checkForm.AcceptDepartment" disabled></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                        <el-form-item label="协同人员:" prop="PersonOfDuty">
                                            <el-input v-model="checkForm.PersonOfDuty" disabled></el-input>
                                        </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                                <el-form-item label="备注:" prop="Remark">
                                    <el-input v-model="checkForm.Remark" disabled></el-input>
                                </el-form-item>
                                    <el-row style="height: 40px;">
                                    <el-col :span="2.5">
                                        <span>是否需审核:</span>
                                    </el-col>
                                    <el-col :span="2">
                                        <el-switch v-model="checkForm.IsNeedApproval" disabled></el-switch>
                                    </el-col>
                                </el-row>
                                <el-form-item label="任务进度:">
                                    <el-progress :percentage="pValue1" :stroke-width="12" style="margin-top: 10px;" ></el-progress>
                                </el-form-item>
                                    <!-- <div v-show="showtoolbar" style="margin-left: 50%;">
                                    <toolbar :buttonList="buttonList2" @callFunction="callFunction" :buttonListmsg="buttonListmsg2"></toolbar>
                                    </div> -->
                                <el-form-item label="附件:">
                                    <ul>
                                        <li v-for="(item,idx) in checkForm.Files" :key="idx" style="line-height: 20px;" ><a target="_blank" :href="item.FileAddress">{{item.FileName}}</a></li>
                                    </ul>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <el-col :span="12">
                                <el-card id="managercheckcard">
                                <div slot="header">
                                    <span style="font-size:20px">工作进度维护</span>
                                    <el-row v-show="istaskpro">
                                    <el-button  type="text">新增</el-button>
                                    <!-- <el-button  type="text" @click="editpro">编辑</el-button> -->
                                    <el-button  type="text">删除</el-button>
                                    </el-row>            
                                </div> 
                                <div class="tasksdiv">
                                    <!--列表-->
                                    <el-table
                                        :data="tasks" 
                                        highlight-current-row
                                        >
                                        <el-table-column type = "index"></el-table-column>
                                        <el-table-column label="进度内容" prop="ProgressDescription">
                                            <template slot-scope = "scope">
                                                <span style="font-size:3px;">{{scope.row.ProgressDescription}}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="进度" prop="ProgressValue" min-width="50%"></el-table-column>
                                        <el-table-column label="添加时间" min-width="85%" prop="CreatedOn" :formatter="formatCreatedOn"></el-table-column>
                                        <el-table-column label="附件" min-width="45%" prop="FileAddress" >
                                            <template slot-scope = "scope">
                                                <!-- <a target="_blank" :href="scope.row.FileAddress">{{scope.row.FileName}}</a> -->
                                                <ul>
                                                    <li v-for="(item,idx) in scope.row.Files" :key="idx" style="line-height: 20px;" >
                                                        <a target="_blank" :href="item.FileAddress">{{item.FileName}}</a>
                                                    </li>
                                                </ul>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </el-card>
                            </el-col>
                        </div>
                        <!-- <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click.native="checkSubmit">完成</el-button>
                        </div> -->
                    </el-dialog>

            </div>
            <!-- <div v-show="isuser" style="width:1000px;height: 1000px;">
                <PieCharttool :key = "key" :userid = "userid"></PieCharttool>
            </div> -->
        </el-col>
    </section>
</template>
<script>
import util from '../../../util/date';
import PieCharttool from "../../components/PieCharttool"
import FileSaver from "file-saver";
import XLSX from "xlsx";
import xlsxstyle from "xlsx-style";
import { Loading } from 'element-ui'
import {GetEachPeoplesUsingTaskNumberGroupByDayChartData,QueryPeoplePingfenReport,GetChildrenList,QueryPageTodayOperatorProcessByUsercode,QueryPageTodayOperatorTasksByUsercode,QueryTasksById,QueryTaskProgressByTaskId,QueryChildTasksByParentId,GetUserTopDepartmentList,GetDepartmentPostUserList,GetYearPlanListByUserCode} from "../../api/oa"

export default {
     components: {  PieCharttool },
    data(){
        return{
            loading: '',
            lookdate:new Date(),
            Pagination: true,
            currentPage: 1, // 当前页码
            total: null, // 总条数
            totaldata:null,
            processtotal:null,
            processtotaldata:null,
            processlist:[],
            pageSize: 20000, // 每页的数据条数
            page: 1,
            DepartcurrentPage: 1, // 当前页码
            Departtotal: null, // 总条数
            Departtotaldata:null,
            DepartpageSize:  100000, // 每页的数据条数
            Departpage: 1,
            departmentsearchdata: [],
            TypeIdModules: [],   //部门类型集合
            users: [],
            departusers: [],
            departmentList: [],
            DptProps: {
                label: 'Name',
                isLeaf: 'Leaf'
            },
            currentRow: null,
            DepartUserRow: null,
            workItem: null,
            taskClassify: 0,
            taskStatus: 0,
            progressValue: null,
            deptinput: null,
            madedate: null,
            cascadervalue: null,
            ClassifyModules:[
                {
                    value: 0,
                    label: "全部"
                },
                {
                    value: 1,
                    label: "日常工作"
                },
                {
                    value: 2,
                    label: "跨部门协调"
                },
                {
                    value: 3,
                    label: "例会项目"
                },
                {
                    value: 4,
                    label: "年度计划"
                },
                {
                    value: 5,
                    label: "专项会议"
                },
            ],
            StatusModules: [
                {
                    value: 0,
                    label: "全部"
                },
                {
                    value: 1,
                    label: "进行中"
                },
                // {
                //     value: 1,
                //     label: "已完成审核中"
                // },
                {
                    value: 2,
                    label: "已完成"
                },
                // {
                //     value: 3,
                //     label: "退回"
                // },
                // {
                //     value: 4,
                //     label: "新建任务待审核"
                // },
                // {
                //     value: 5,
                //     label: "取消任务待审核"
                // },
                // {
                //     value: 6,
                //     label: "新建任务退回" 
                // },
               
            ],
            checkVisible: false,
            checkForm: {            //查看表单初始化
                Id: null,
                WorkItem: null,
                WorkDescription: null,
                PlanComplateTime: null,
                Remark: null,
                IsNeedApproval: null
            },
            cols:[
            ],
            showAssign: false,
            createPerson: false,                    //创建人是否显示
            showyearplan: false,                    //年度计划查看界面显示控制
            showorganize: false,                     //跨部门协同查看界面显示控制
            showregularproject: false,               //例会来源查看界面控制
            showprofessionmeetong: false,            //专项会议查看界面控制
            CommonTaskClassifyText: null,
            pValue1: null,   //进度条数值
            istaskpro: false,
            tasks: [],
            options: [],
            checkdepartid: null,
            departuserid: null,
            departmentName:'',
            props: {
                checkStrictly: true,
                lazy: true,
                lazyLoad (node, resolve) {
                    const { level,value } = node;
                    if(node.level == 0){
                        var user = JSON.parse(window.localStorage.user);
                        var userid="b57e4ab5-7df4-482b-a8f3-a36f012b963f";
                        if (user.name == '马玲' || user.name == '杨光') {
                          userid = 'b57e4ab5-7df4-482b-a8f3-a36f012b963f';
                        }
                        else if (user.name == '刘丽丽' || user.name == '尹滕滕' || user.name == '李欣晟')//销售工程公司
                        {
                          userid = '971e3fb0-f33a-45d1-bc28-a36f012b8df2';
                        }
                        else if (user.name == '田婷婷' || user.name == '张刚')
                        {
                          userid = '33824a20-4d5e-4498-8786-a36f012b95e1';
                        }
                        else if (user.name == '赵娜')//人防公司
                        {
                          userid = 'f919ed9f-8660-44aa-96aa-a36f012b8cc7';
                        }
                        else if (user.name == '谢娇')//洁净空调
                        {
                            userid = 'ab753a23-e2ce-4c0d-96f3-a36f012b8892';
                        }
                        else if (user.name == '苏兴家')//能源
                        {
                            userid = '898dacfe-b63c-4b22-9646-a36f012b8d16';
                        }
                        else if (user.name == '路来亮')//通风二
                        {
                            userid = '679337b8-ccea-4449-9460-a36f012b8c27';
                        }
                        else if (user.name == '孙成伟')//安装
                        {
                            userid = 'db7992cd-88fa-4f1d-8557-a36f012b8cfa';
                        }
                        else if (user.name == '陈洪星')//通风一
                        {
                            userid = '4a745436-1600-4dc6-85da-a36f012b8665';
                        }
                        else if (user.name == '曹洪金')//通风配件
                        {
                            // userid = '8376b7af-d8fd-4d72-9545-9cf3de637bfe';
                            userid = 'd44af8f9-ced1-411d-8b0f-7f3ecbbaec9d';
                        }
                        else if (user.name == '韩朝晖')//环保一、活性炭
                        {
                            userid = '9928b2e3-61ec-4a8a-8067-a36f012b886d';
                        }
                        else if (user.name == '付志浩')//环保科技
                        {
                            userid = '9928b2e3-61ec-4a8a-8067-a36f012b886d';
                        }
                        else if (user.name == '邵小红')//风电
                        {
                            userid = '33824a20-4d5e-4498-8786-a36f012b95e1';
                        }
                        else if (user.name == '陈卫卫')//管业
                        {
                            userid = 'ff176d0e-4213-493f-9be4-a36f012b942e';
                        }
                        else if (user.name == '李沛沛')//复材制品
                        {
                            userid = 'dcffef71-be23-4555-ab3d-a36f012b9559';
                        }
                        else if (user.name == '涂美铃')//复材科技
                        {
                            userid = '00861836-d4cf-4d23-b3f4-526fe507b59e';
                        }
                        else if (user.name == '白茹')//公共管理中心
                        {
                            userid = '418207c2-c8cd-403b-b719-a36f012b968e';
                        }
                        else if (user.name == '康佳')//公共管理中心
                        {
                            userid = '418207c2-c8cd-403b-b719-a36f012b968e';
                        }
                        else if (user.name == '贾欣慧')
                        {
                            userid = '418207c2-c8cd-403b-b719-a36f012b968e';
                        }
                        else if(user.name == '刘绪璐')
                        {
                            userid = '0b84fc71-ade2-46d9-814f-a36f012b8be1';
                        }
                        else {
                          userid = user.sub;
                        }

                        GetUserTopDepartmentList({userId: userid}).then(res => {
                            // const nodes = res.data.response.map(x=>({
                            //     value: x.Id,
                            //     label: x.Name,
                            //     leaf: x.Leaf
                            // }))
                            const nodes = res.data.data.map(x=>({
                                value: x.deptId,
                                label: x.deptName,
                                leaf: !x.hasNode
                            }))
                            resolve(nodes)                         
                        });
                    }else{
                        // GetChildrenList({departmentId: node.value}).then(res => {
                        //     const nodes = res.data.response.map(x=>({
                        //         value: x.Id,
                        //         label: x.Name,
                        //         leaf: x.Leaf
                        //     }))
                        //     resolve(nodes)
                        // })
                        GetChildrenList({deptId: node.value}).then(res => {
                            // console.log(res);
                            var mydata = res.data.data;
                            if(mydata.dept[0].children!=null)
                            {
                                const nodes = res.data.data.dept[0].children.map(x=>({
                                    value: x.id,
                                    label: x.label,
                                    leaf: !x.hasChildren
                                }))
                                // console.log(nodes);
                                resolve(nodes)   
                            }else{
                                resolve([]);
                            }
                        })
                    }
                    
                }
            },
            YearPlanTaskList: [],
            YearPlanTask: null,
            YearPlanTaskId: null,
            isdepartment: false,
            departid: [],
            peoplecode:[],
            key: 0,
            userid: '',
            isuser: false,
            spanArr:[],
            pos:0,
            spanArr2:[],
            pos2:0,
            spanArr3:[],
            pos3:0
        }
    },
    methods:{
        tableRowClassName({row, rowIndex}) {
            // console.log(row)
            if (row.Exclude) {
                return 'exclude-row';
            }
            return '';
        },
        handleExport()
        {
            this.exportExcel();
        },
        //定义导出Excel表格事件
        exportExcel() {
            /* 从表生成工作簿对象 */
            var wb = XLSX.utils.table_to_book(document.querySelector("#mytable"));
            this.setExlStyle(wb['Sheets']['Sheet1']);
            let wb_out = xlsxstyle.write(wb,{type:'buffer'});
            /* 获取二进制字符串作为输出 */
            var wbout = XLSX.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array"
            });
            var filename=this.departmentName;
            try {
                FileSaver.saveAs(
                //Blob 对象表示一个不可变、原始数据的类文件对象。
                //Blob 表示的不一定是JavaScript原生格式的数据。
                //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
                //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
                // new Blob([wbout], { type: "application/octet-stream" }),
                new Blob([wb_out], { type: "application/octet-stream" }),
                //设置导出文件名称
                filename+"WT未使用情况报表.xlsx"
                );
            } catch (e) {
                if (typeof console !== "undefined") console.log(e, wbout);
            }
            return wbout;
        },
        setExlStyle(data) {
            let borderAll = {  //单元格外侧框线
                top: {
                style: 'thin',
                },
                bottom: {
                style: 'thin'
                },
                left: {
                style: 'thin'
                },
                right: {
                style: 'thin'
                }
            };
            data['!cols'] = [];
            for (let key in data) {
                // console.log(key)
                if (data[key] instanceof Object) {
                var row = key.replace(/[^0-9]/ig, '')
                data[key].s = {
                    border: borderAll,
                    alignment: {
                    horizontal: 'center',   //水平居中对齐
                    vertical:'center',
                    wrapText: 1
                    },
                    fill: row==1?{fgColor: { rgb: '4FADC2' }}:{fgColor: { indexed: 64 }},
                    font:{
                    name:'微软雅黑',
                    sz:11,
                    },
                    bold:true,
                    numFmt: 0
                }
                data['!cols'].push({wpx: 115});
                }
            }
            return data;
        },
        merageInit() {
            this.spanArr = [];
            this.pos = 0;
            this.spanArr2 = [];
            this.pos2 = 0;
            this.spanArr3 = [];
            this.pos3 = 0;
        },
         getSpanArr(data) {　
            //console.log(data) 
            this.merageInit();
            for (var i = 0; i < data.length; i++) {
                if (i === 0) {
                    this.spanArr.push(1);
                    this.pos = 0
                    this.spanArr2.push(1);
                    this.pos2 = 0;
                } else {
                    // 判断当前元素与上一个元素是否相同
                    if (data[i].WorkItem === data[i - 1].WorkItem) {
                        this.spanArr[this.pos] += 1;
                        this.spanArr.push(0);
                    } else {
                        this.spanArr.push(1);
                        this.pos = i;
                    }
                    if (data[i].WorkDescription === data[i - 1].WorkDescription && data[i].WorkItem === data[i - 1].WorkItem) {
                        this.spanArr2[this.pos2] += 1;
                        this.spanArr2.push(0);
                    } else {
                        this.spanArr2.push(1);
                        this.pos2 = i;
                    }
                    if (data[i].PersonOfDuty === data[i - 1].PersonOfDuty && data[i].WorkDescription === data[i - 1].WorkDescription && data[i].WorkItem === data[i - 1].WorkItem) {
                        this.spanArr3[this.pos3] += 1;
                        this.spanArr3.push(0);
                    } else {
                        this.spanArr3.push(1);
                        this.pos3 = i;
                    }
                }
                //console.log(this.spanArr)
            }
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        //   if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
        //   if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
          if (columnIndex === 1 ) {
            const _row = this.spanArr[rowIndex];
            const _col = _row > 0 ? 1 : 0;
            //console.log(`rowspan:${_row} colspan:${_col}`)
            return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
                  rowspan: _row,
                  colspan: _col
            }
          }
           else if (columnIndex === 2) {
            // 第二列的合并方法
            const row2 = this.spanArr2[rowIndex];
            const col2 = row2 > 0 ? 1 : 0; // 如果被合并了row = 0; 则他这个列需要取消
            return {
                rowspan: row2,
                colspan: col2,
            };
          }
        //   else if (columnIndex === 3) {
        //     // 第三列的合并方法
        //     const row3 = this.spanArr3[rowIndex];
        //     const col3 = row3 > 0 ? 1 : 0; // 如果被合并了row = 0; 则他这个列需要取消
        //     return {
        //         rowspan: row3,
        //         colspan: col3,
        //     };
        //   }
        },
        getLookDate:function()
        {
            return this.lookdate;
        },
        handleDateChange()
        {
            // console.log(this.lookdate)
            if(this.checkdepartid!=null)
            {
                // this.getDepartUsers(this.checkdepartid);
                this.LoadReportData();
            }
        },
        call(arr)
        {
            let para = {
                userCode: arr[0],
                date:this.lookdate,
                pageIndex: this.page,
                pageSize: this.pageSize
            };
            QueryPageTodayOperatorTasksByUsercode(para).then((res) => {
                //console.log(res)
                 this.total = res.data.response.pageCount;
                 this.totaldata = res.data.response.dataCount;
                 this.users = res.data.response.data;
            });
            var username=arr[1];
            let para2 = {
                userCode: username,
                date:this.lookdate,
                pageIndex: this.page,
                pageSize: this.pageSize
            };
            //console.log(para2)
            QueryPageTodayOperatorProcessByUsercode(para2).then((res) => {
                //console.log(res)
                 this.processtotal = res.data.response.pageCount;
                 this.processtotaldata = res.data.response.dataCount;
                 this.processlist = res.data.response.data;
            });
        },
       //双击查看详细信息
     checkInfo(row){
        //  console.log(row)
          this.checkVisible = true;
        if(row.TaskClassify ==1){  
            // this.CommonTaskClassifyText = row.CommonTaskClassifyText;
            this.CommonTaskClassifyText = "日常工作";
                this.showorganize = false;
                this.showyearplan = false;
                this.showregularproject = false;
                this.showprofessionmeetong = false;
                if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                }else{
                   this.showAssign = false; 
                }
                this.createPerson = true;
        }else{
                if(row.TaskClassify ==2)
                {
                    this.CommonTaskClassifyText = "跨部门协调";
                    this.showorganize = true;
                    this.showyearplan = false;
                    this.showregularproject = false;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = false;
                }
                else if(row.TaskClassify ==3)
                {
                    this.CommonTaskClassifyText =  "例会项目";
                    this.showorganize = false;
                    this.showyearplan = false;
                    this.showregularproject = true;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = true;
                }else if(row.TaskClassify ==4)
                {
                    this.CommonTaskClassifyText =  "年度计划";
                    this.showorganize = false;
                    this.showyearplan = true;
                    this.showregularproject = false;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = false;
                }else if(row.TaskClassify ==5){
                    this.CommonTaskClassifyText = "专项会议";
                    this.showorganize = false;
                    this.showyearplan = false;
                    this.showregularproject = false;
                    this.showprofessionmeetong = true;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = true;
                }
                else {
                    this.CommonTaskClassifyText =  "其他";
                    this.showorganize = false;
                    this.showyearplan = false;
                    this.showregularproject = false;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = false;
                }
            }
            QueryTasksById({Id: row.TaskId,taskClassify: row.TaskClassify}).then((res) => {
                this.checkForm = res.data.response;
                this.checkForm.Files=[];
                if(res.data.response.FileName!=null)
                {
                    var arr=res.data.response.FileName.split(",");
                    var arr2=res.data.response.FileAddress.split(",");
                    var files=[];
                    for(var a=0;a<arr.length;a++)
                    {
                        var f={};
                        f.FileName=arr[a];
                        f.FileAddress=arr2[a];
                        files.push(f);
                    }
                    this.checkForm.Files=files;
                }
            })
          this.pValue1 = row.TaskProgressValue;
        //   this.checkForm = Object.assign({},row);
          let para = {taskId: row.TaskId};
          QueryTaskProgressByTaskId(para).then((res) => {
            //   this.tasks = res.data.response;
              var data =res.data.response;
              let formatData=new Array();
              for(var a=0;a<data.length;a++)
              {
                    var element = data[a];
                    element.Files=[];
                    if(element.FileName!=null)
                    {
                        var arr=element.FileName.split(",");
                        var arr2=element.FileAddress.split(",");
                        var files=[];
                        for(var d=0;d<arr.length;d++)
                        {
                            var f={};
                            f.FileName=arr[d];
                            f.FileAddress=arr2[d];
                            files.push(f);
                        }
                        element.Files=files;
                    }
                    formatData.push(element);
              }
              this.tasks = formatData;
          });
        },
        selectDepartUserRow(val){
            this.DepartUserRow = val;
            this.departuserid = val.id;

            this.peoplecode=[];
            this.peoplecode.push(val.id);
            var month=this.lookdate.getFullYear()+'-'+Number(this.lookdate.getMonth()+1);
            GetEachPeoplesUsingTaskNumberGroupByDayChartData({userlist:this.peoplecode,month:month}).then(res => {
                var data = res.data.response;
                this.cols=[];
                this.users=new Array();

                if(data.length>0)
                {
                    var strarrlength=data[0].strArr2.length;

                    for(var a=1;a<=strarrlength;a++)
                    {
                        this.cols.push({prop:'day'+a,label:this.lookdate.getFullYear()+'-'+Number(this.lookdate.getMonth()+1)+'-'+(a)});
                    }
                    var formatData=[];
                    for(var d=0;d<data.length;d++)
                    {
                        var obj={};
                        obj.company=data[d].OrganizationName;
                        obj.department=data[d].DepartmentName;
                        obj.name=data[d].Name;
                        obj.zhiwujibie=data[d].PostRank;
                        obj.TaskTotal=data[d].TaskTotal;
                        obj.ProgressTotal=data[d].ProgressTotal;
                        obj.Exclude=data[d].Exclude;
                        obj.day1=data[d].strArr2[0];
                        obj.day2=data[d].strArr2[1];
                        obj.day3=data[d].strArr2[2];
                        obj.day4=data[d].strArr2[3];
                        obj.day5=data[d].strArr2[4];
                        obj.day6=data[d].strArr2[5];
                        obj.day7=data[d].strArr2[6];
                        obj.day8=data[d].strArr2[7];
                        obj.day9=data[d].strArr2[8];
                        obj.day10=data[d].strArr2[9];
                        obj.day11=data[d].strArr2[10];
                        obj.day12=data[d].strArr2[11];
                        obj.day13=data[d].strArr2[12];
                        obj.day14=data[d].strArr2[13];
                        obj.day15=data[d].strArr2[14];
                        obj.day16=data[d].strArr2[15];
                        obj.day17=data[d].strArr2[16];
                        obj.day18=data[d].strArr2[17];
                        obj.day19=data[d].strArr2[18];
                        obj.day20=data[d].strArr2[19];
                        obj.day21=data[d].strArr2[20];
                        obj.day22=data[d].strArr2[21];
                        obj.day23=data[d].strArr2[22];
                        obj.day24=data[d].strArr2[23];
                        obj.day25=data[d].strArr2[24];
                        obj.day26=data[d].strArr2[25];
                        obj.day27=data[d].strArr2[26];
                        obj.day28=data[d].strArr2[27];
                        if(data[d].strArr2.length>28)
                        {
                            obj.day29=data[d].strArr2[28];
                        }
                        if(data[d].strArr2.length>29)
                        {
                            obj.day30=data[d].strArr2[29];
                        }
                        if(data[d].strArr2.length>30)
                        {
                            obj.day31=data[d].strArr2[30];
                        }
                        formatData.push(obj);
                    }
                    this.users=formatData;
                }
            });
        },
        YearPlanchange(val){
            this.YearPlanTask = val.split('|')[2];
            this.YearPlanTaskId = val.split('|')[1];
        },
        load(tree,treeNode,resolve){
            let para = {
                taskId: tree.Id,
            };
            QueryChildTasksByParentId(para).then((res) => {
                resolve(res.data.response)
            });
        },
        formatPlanComplateTime: function (row,colume) {
            return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime),'yyyy-MM-dd');
        },
        formatCreatedOn: function (row,colume) {
                return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn),'yyyy-MM-dd hh:mm');
            },
        formatCommonTaskClassifyText: function(row,colume){
                if(row.TaskClassify ==1){  
                    // return row.CommonTaskClassifyText;
                    return "日常工作";
                }else{
                    if(row.TaskClassify ==2)
                    {
                        return "跨部门协调";
                    }
                    else if(row.TaskClassify ==3)
                    {
                        return "例会项目";
                    }else if(row.TaskClassify ==4)
                    {
                        return "年度计划";
                    }else if(row.TaskClassify ==5){
                        return "专项会议";
                    }
                    else {
                        return "其他";
                    }
                }
            },
        formatSource: function (row,column) {
                return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
            },
        formatCreateTime: function(row,column) {
                return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
            },
        formatTipCreateTime: function (row, column) {
                return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime),'yyyy-MM-dd hh:mm');
            },
        formatTipEndTime: function (row, column) {
                return (!row.ENDTIME || row.ENDTIME == '') ? '' : util.formatDate.format(new Date(row.ENDTIME),'yyyy-MM-dd hh:mm');
            },
        formatEndTime: function (row, column) {
                return (!row.ENDTIME || row.ENDTIME == '') ? '' : util.formatDate.dateformat(new Date(row.ENDTIME));
            },
        //翻页处理
        handleCurrentChange(val) {
           this.page = val;
           this.getManagerChecks();
           this.page = 1;
      },
      getManagerChecks(){
           //时间转化
             let begintime = "";  //开始时间
             let endtime = "";    //结束时间
            if(this.madedate != null){
                switch (this.madedate.length) {
                    case 1:
                    begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
                    break;
                    case 2:
                    begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
                    endtime += util.formatDate.format(new Date(this.madedate[1]), 'yyyy-MM-dd');
                    break;
                    default:
                    break;
                }    
            }
            if(this.departuserid == null){
                 this.$message({
                    message: '请先选择想要查看任务的部门人员',
                    type: 'warning',
                    duration: 7000
                });
                return;
            }
            let para = {
                userCode: this.departuserid,
                workItem: this.workItem,
                taskClassify: this.taskClassify,
                taskStatus: this.taskStatus,
                begin: begintime,
                end: endtime,
                yearplanId: this.YearPlanTaskId,
                progressValue: this.progressValue,
                pageIndex: this.page,
                pageSize: this.pageSize
            };
            QueryPageTodayOperatorTasksByUsercode(para).then((res) => {
                 this.total = res.data.response.pageCount;
                 this.totaldata = res.data.response.dataCount;
                 this.users = res.data.response.data;
            });
      },
        cascaderchange(node){
            // 目的是选择之后将下拉界面收起
            this.$refs.department.toggleDropDownVisible();
            this.Pagination = false;
            let pathvalue = this.$refs.department.getCheckedNodes()[0];
            this.departmentName=pathvalue.label;
            this.checkdepartid = pathvalue.value;
            this.getDepartUsers(pathvalue.value);
            this.isdepartment = true;
            this.isuser = false;
            // this.key += 1;
            this.$refs.department.dropDownVisible = false;
        },
        handleCurrentChangeDepart(val){
           if(this.checkdepartid==null)
           {
               return;
           }
           this.Departpage = val;
           this.getDepartUsers(this.checkdepartid);
           this.Departpage = 1;
        //    this.key += 1;
        },
        LoadReportData()
        {
            //console.log(this.departusers);
            this.peoplecode=[];
            this.departusers.forEach(item => {
                this.peoplecode.push(item.id);
            });
            //console.log(this.peoplecode)
            // var month=this.lookdate.getFullYear()+'-'+Number(this.lookdate.getMonth()+1);
            // QueryPeoplePingfenReport({peoples:this.peoplecode,month:month}).then(res => {
            //     var response = res.data.response.data;
            //     // console.log(response);
            //     this.users=response;
            //     this.getSpanArr(this.users);
            // });
            this.Loadingstart();
            var month=this.lookdate.getFullYear()+'-'+Number(this.lookdate.getMonth()+1);
            //alert(month)
            GetEachPeoplesUsingTaskNumberGroupByDayChartData({userlist:this.peoplecode,month:month}).then(res => {
                
                var data = res.data.response;
                // this.cols.push({prop:'company',label:'公司'});
                // this.cols.push({prop:'department',label:'部门'});
                // this.cols.push({prop:'name',label:'姓名'});
                // this.cols.push({prop:'zhiwujibie',label:'职务级别'});
                //console.log(data)
                this.cols=[];
                this.users=new Array();

                if(data.length>0)
                {
                    var strarrlength=data[0].strArr2.length;

                    for(var a=1;a<=strarrlength;a++)
                    {
                        this.cols.push({prop:'day'+a,label:this.lookdate.getFullYear()+'-'+Number(this.lookdate.getMonth()+1)+'-'+(a)});
                    }
                    var formatData=[];
                    for(var d=0;d<data.length;d++)
                    {
                        var obj={};
                        obj.Id=data[d].Id;
                        obj.company=data[d].OrganizationName;
                        obj.department=data[d].DepartmentName;
                        obj.name=data[d].Name;
                        obj.zhiwujibie=data[d].PostRank;
                        obj.TaskTotal=data[d].TaskTotal;
                        obj.ProgressTotal=data[d].ProgressTotal;
                        obj.Exclude=data[d].Exclude;
                        obj.day1=data[d].strArr2[0];
                        obj.day2=data[d].strArr2[1];
                        obj.day3=data[d].strArr2[2];
                        obj.day4=data[d].strArr2[3];
                        obj.day5=data[d].strArr2[4];
                        obj.day6=data[d].strArr2[5];
                        obj.day7=data[d].strArr2[6];
                        obj.day8=data[d].strArr2[7];
                        obj.day9=data[d].strArr2[8];
                        obj.day10=data[d].strArr2[9];
                        obj.day11=data[d].strArr2[10];
                        obj.day12=data[d].strArr2[11];
                        obj.day13=data[d].strArr2[12];
                        obj.day14=data[d].strArr2[13];
                        obj.day15=data[d].strArr2[14];
                        obj.day16=data[d].strArr2[15];
                        obj.day17=data[d].strArr2[16];
                        obj.day18=data[d].strArr2[17];
                        obj.day19=data[d].strArr2[18];
                        obj.day20=data[d].strArr2[19];
                        obj.day21=data[d].strArr2[20];
                        obj.day22=data[d].strArr2[21];
                        obj.day23=data[d].strArr2[22];
                        obj.day24=data[d].strArr2[23];
                        obj.day25=data[d].strArr2[24];
                        obj.day26=data[d].strArr2[25];
                        obj.day27=data[d].strArr2[26];
                        obj.day28=data[d].strArr2[27];
                        if(data[d].strArr2.length>28)
                        {
                            obj.day29=data[d].strArr2[28];
                        }
                        if(data[d].strArr2.length>29)
                        {
                            obj.day30=data[d].strArr2[29];
                        }
                        if(data[d].strArr2.length>30)
                        {
                            obj.day31=data[d].strArr2[30];
                        }
                        // obj.workItem="workItem";
                        // obj.name="a"
                        // obj.company="b"
                        // obj.department="c"
                        // obj.zhiwujibie="d"
                        // for(var day=0;day<strarrlength;day++)
                        // {
                        //     obj.day1="1,2";
                        // }


                        var result = this.departusers.filter(u=>u.id==obj.Id);
                        // console.log(result);
                        obj.levelValue = result[0].levelValue;
                        formatData.push(obj);
                        //console.log(formatData);
                    }
                    this.users=formatData;
                }
                this.Loadignend();
            });
            
        },
        Loadingstart () {
            this.loading = Loading.service({
                lock: true,
                text: '正在获取统计数据...',
                background: 'rgba(0,0,0,0.1)'
            })
        },
        Loadignend () {
            this.loading.close();
        },
        //获取部门人员
        getDepartUsers(node){
            let para = {
                deptId: node,
                isAllNextDept:true,
                pageNum: this.Departpage,
                pageSize: this.DepartpageSize,
                includeChildren:true,
                onlyPrincipal:true
            }
            GetDepartmentPostUserList(para).then(res => {
                var data=res.data.data.list;
                // console.log(data)
                let newdata=this.unique(data);
                let arr=[];
                newdata.forEach(element => {
                    if(element.workTypeName=='后勤')
                    {
                        arr.push(element);
                    }
                });
                // this.departusers = newdata;
                this.departusers = arr;
                // this.departusers = newdata;
                this.Pagination = true;
                // this.departid = newdata;
                this.departid = arr;
                // this.departid = res.data.response.data;
                this.key += 1;
                this.Departtotal = res.data.data.pages;
                this.Departtotaldata = res.data.data.total;
                this.peoplecode=[];
                this.LoadReportData();
            });

        },
        unique(arr) {
            const res = new Map();
            return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
        }

    },
    mounted(){
        
    }
}
</script>
<style>
.el-table .exclude-row {
    background: #FF5722;
}
</style>
<style scoped>
.orgtree {
  /* overflow-y: auto;
  overflow-x: scroll; */
  height: calc(100vh - 280px);
  width:200px;
  border: 0px solid blue;
  padding-top: 35px;
}
.orgbtndivtree {
  padding-left: 20px;
  padding-right: 20px;
  
}
/deep/.el-table__body tr.current-row>td {
  background: #FABE64 !important;
}
/deep/input:disabled,/deep/textarea:disabled{
    opacity: 1;
    -webkit-text-fill-color: rgb(12, 12, 12);
}
.managercheckdiv {
 height: 600px;
}
#managercheckcard{
    height: 600px;
}
.tasksdiv {
    max-height: 400px;
    overflow: auto;
}
/deep/.el-table .warning-row {
    background: #F0F8FF;
}
/deep/.el-button--goon {
  color: #FFF;
  background-color: #20B2AA;
  border-color: #20B2AA;
}
/deep/.el-button--goon:hover {
  background: #48D1CC;
  border-color: #48D1CC;
  color: #fff;
}
/deep/.el-button--goon:focus {
  background: #20B2AA;
  border-color: #20B2AA;
  color: #fff;
}
</style>